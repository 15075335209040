import React from 'react'
import { MqDesktop, MqTablet } from 'streamr-ui/css/consts'
import styled from 'styled-components'

export default function PersonaPageSection({
    background,
    mobileBackground = background,
    gradient,
    mobileGradient = gradient,
    children,
}) {
    return (
        <Root
            $gradient={gradient}
            $mobileGradient={mobileGradient}
            $background={background}
            $mobileBackground={mobileBackground}
        >
            {children}
        </Root>
    )
}

const Root = styled.div`
    background: linear-gradient(${({ $mobileGradient }) => $mobileGradient});
    background: ${({ $mobileBackground }) => $mobileBackground};
    margin-top: -4.5rem;

    @media ${MqTablet} {
        background: linear-gradient(${({ $gradient }) => $gradient});
        background: ${({ $background }) => $background};
        margin-top: -4rem;
    }

    @media ${MqDesktop} {
        margin-top: -4.5rem;
    }
`
